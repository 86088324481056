import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";

import "firebase/storage";
// Config here
const firebaseConfig = {
  apiKey: "AIzaSyCHeQ66vsOvGVAtUHkHlIRnaqiBo4Xor8c",
  authDomain: "coedify-tech-f02b9.firebaseapp.com",
  projectId: "coedify-tech-f02b9",
  storageBucket: "coedify-tech-f02b9.appspot.com",
  messagingSenderId: "229454740830",
  appId: "1:229454740830:web:6c395bb32c15e7980be3cf",
  measurementId: "G-36JTMDETVP",
};

const firebase = initializeApp(firebaseConfig);
const db = getFirestore();
const storage = getStorage(firebase);

export { firebase, db, storage, ref, uploadBytes };
