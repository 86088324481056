export const List = [
  {
    id: 1,
    name: "axon",
    title: "DBA",
    url: "https://jumpx-react.envytheme.com/images/clients/client2.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam quod animi unde repudiandae sunt doloribus reprehenderit voluptates repellat at consectetur porro praesentium qu",
    rating: 4,
  },
  {
    id: 2,
    name: "Johnson",
    title: "backEnd",
    url: "https://jumpx-react.envytheme.com/images/clients/client2.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam quod animi unde repudiandae sunt doloribus reprehenderit voluptates repellat at consectetur porro praesentium qu",
    rating: 4,
  },
  {
    id: 3,
    name: "john",
    title: "frontEnd",
    url: "https://jumpx-react.envytheme.com/images/clients/client2.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam quod animi unde repudiandae sunt doloribus reprehenderit voluptates repellat at consectetur porro praesentium qu",
    rating: 4,
  },
  {
    id: 4,
    name: "alen",
    title: "manager",
    url: "https://jumpx-react.envytheme.com/images/clients/client2.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam quod animi unde repudiandae sunt doloribus reprehenderit voluptates repellat at consectetur porro praesentium qu",
    rating: 4,
  },
  {
    id: 5,
    name: "axon",
    title: "SEO",
    url: "https://jumpx-react.envytheme.com/images/clients/client2.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam quod animi unde repudiandae sunt doloribus reprehenderit voluptates repellat at consectetur porro praesentium qu",
    rating: 5,
  },
  {
    id: 6,
    name: "jon",
    title: "Testing",
    url: "https://jumpx-react.envytheme.com/images/clients/client2.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam quod animi unde repudiandae sunt doloribus reprehenderit voluptates repellat at consectetur porro praesentium qu",
    rating: 3,
  },
];
