export const Faqs = [
  {
    id: 1,
    question: "Lorem ipsum dolor sit",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate neque vitae expedita ab, sit rerum, eligendi praesentium consequatur ipsa non, distinctio rem repudiandae eveniet ea sequi ratione temporibus nihil placeat eius explicabo perspiciatis voluptas. Perferendis provident voluptate vel dolores facilis",
  },
  {
    id: 2,
    question: "Lorem ipsum dolor sit",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate neque vitae expedita ab, sit rerum, eligendi praesentium consequatur ipsa non, distinctio rem repudiandae eveniet ea sequi ratione temporibus nihil placeat eius explicabo perspiciatis voluptas. Perferendis provident voluptate vel dolores facilis",
  },
  {
    id: 3,
    question: "Lorem ipsum dolor sit",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate neque vitae expedita ab, sit rerum, eligendi praesentium consequatur ipsa non, distinctio rem repudiandae eveniet ea sequi ratione temporibus nihil placeat eius explicabo perspiciatis voluptas. Perferendis provident voluptate vel dolores facilis",
  },
  {
    id: 4,
    question: "Lorem ipsum dolor sit",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate neque vitae expedita ab, sit rerum, eligendi praesentium consequatur ipsa non, distinctio rem repudiandae eveniet ea sequi ratione temporibus nihil placeat eius explicabo perspiciatis voluptas. Perferendis provident voluptate vel dolores facilis",
  },
];
